import {
  WEEKLY,
  DAILY,
  MONTHLY,
  YEARLY,
  IMPORTANT,
  URGENT,
  CIRCUMSTANTIAL,
  JUST_TODAY,
  PERIOD,
} from '@/constants/index';
import {
  ActivityModel,
  Goal,
  HabitModel,
  SelectorTriadTime,
  TaskModel,
} from '@/store/modules';
import {
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  differenceInYears,
  formatDateToServer,
  subDays,
  formatDate,
  addDays,
  startOfWeek,
  startOfMonth,
  week,
} from '@/utils/date';
import {parseToNumber} from '@/utils/string';
import { parseISO } from 'date-fns';
import Gamification from './Gamification';

export function getSequencesActivity(
  activity: HabitModel | TaskModel,
  lowerDate: string,
  upperDate: string,
  dates: any[],
) {
  let nextDate = getPrevDateOfActivity(activity, upperDate);
  let amountDaysHabitDoneCurrent = 0;
  const sequencesDone = [];
  while (lowerDate <= nextDate) {
    const dateDone = dates?.find(
      // eslint-disable-next-line no-loop-func
      item => item.date === nextDate,
    )?.done;
    if (dateDone) {
      amountDaysHabitDoneCurrent += 1;
    } else {
      sequencesDone.push(amountDaysHabitDoneCurrent);
      amountDaysHabitDoneCurrent = 0;
    }
    nextDate = getPrevDateOfActivity(
      activity,
      formatDateToServer(subDays(nextDate, 1)),
    );
  }
  if (amountDaysHabitDoneCurrent) {
    sequencesDone.push(amountDaysHabitDoneCurrent);
  }
  return sequencesDone;
}

export function getPrevDateOfActivity(activity: any, date: string):any {
  if (activity.startDate > date) return null;
  if (activity.endDate < date) return activity.endDate;
  if (activity.period === PERIOD.JUST_TODAY && activity.startDate === date)
    return date;
  if (activity.period === PERIOD.DAILY) return date;
  if (activity.period === PERIOD.WEEKLY) {
    const indexWeek = parseISO(activity.startDate).getDay();
    if (indexWeek === parseISO(date).getDay()) return date;
    const nextDate = formatDateToServer(addDays(startOfWeek(date), indexWeek));
    if (nextDate > date)
      return getPrevDateOfActivity(
        activity,
        formatDateToServer(subDays(startOfWeek(date), 1)),
      );
    return nextDate;
  }
  if (activity.period === PERIOD.MONTHLY) {
    const indexMonth = formatDate(activity.startDate, 'dd');
    if (indexMonth === formatDate(date, 'dd')) return date;
    const nextDate = formatDateToServer(
      addDays(startOfMonth(date), (Number(indexMonth) - 1) as number),
    );
    if (nextDate > date)
      return getPrevDateOfActivity(
        activity,
        formatDateToServer(subDays(startOfMonth(date), 1)),
      );
    return nextDate;
  }
  if (activity.period === PERIOD.YEARLY) {
    const indexYear = formatDate(activity.startDate, 'dd-MM');
    if (indexYear === formatDate(date, 'dd-MM')) return date;
    return `${formatDate(date, 'yyyy')}-${formatDate(
      activity.startDate,
      'MM-dd',
    )}`;
  }
  if (activity.period.includes('EVERY')) {
    const [, days,] = activity.period.split('_');
    const difInDays = differenceInDays(date, activity.startDate);
    const indexDiff = difInDays % parseInt(days);
    return formatDateToServer(subDays(date, indexDiff));
  }
  const periods = activity.period.split(',');
  if (periods.length > 0) {
    const indexs = periods.map((i:string) => week.indexOf(i)).sort((a:number, b:number) => b > a);
    const currentIndex = parseISO(date).getDay();
    if (indexs.includes(currentIndex)) {
      return date;
    }
    const diff = Math.min(...indexs.map((i:number) => currentIndex - i));
    if (diff < 0) {
      return formatDateToServer(subDays(date, 7 + diff));
    }
    return formatDateToServer(subDays(date, diff));
  }
  return null;
}


export function getTotalDaysRepetionActivityBetweenInterval(
  activity: ActivityModel,
  lowerDate: string,
  upperDate: string,
): number {
  if ('specific' in activity) {
    return 1;
  }
  if (activity.period === DAILY) {
    const intervalDate = differenceInDays(upperDate, lowerDate) + 1;
    const intervalCreated = differenceInDays(upperDate, activity.startDate) + 1;
    const intervalActivity =
      differenceInDays(activity.endDate, activity.startDate) + 1;
    return Math.min(intervalDate, intervalCreated, intervalActivity);
  }
  if (activity.period === WEEKLY) {
    const intervalDate = differenceInWeeks(upperDate, lowerDate) + 1;
    const intervalCreated =
      differenceInWeeks(upperDate, activity.startDate) + 1;
    const intervalActivity =
      differenceInWeeks(activity.endDate, activity.startDate) + 1;
    return Math.min(intervalDate, intervalCreated, intervalActivity);
  }
  if (activity.period === MONTHLY) {
    const intervalDate = differenceInMonths(upperDate, lowerDate) + 1;
    const intervalCreated =
      differenceInMonths(upperDate, activity.startDate) + 1;
    const intervalActivity =
      differenceInMonths(activity.endDate, activity.startDate) + 1;
    return Math.min(intervalDate, intervalCreated, intervalActivity);
  }
  if (activity.period === YEARLY) {
    const intervalDate = differenceInYears(upperDate, lowerDate) + 1;
    const intervalCreated =
      differenceInYears(upperDate, activity.startDate) + 1;
    const intervalActivity =
      differenceInYears(activity.endDate, activity.startDate) + 1;
    return Math.min(intervalDate, intervalCreated, intervalActivity);
  }
  const countDaysCustomTask = activity.period
    .split(',')
    .reduce(
      (total: number) =>
        total +
        Math.min(
          differenceInWeeks(upperDate, activity.startDate),
          differenceInWeeks(upperDate, lowerDate),
          differenceInWeeks(activity.endDate, activity.startDate),
        ) +
        1,
      0,
    );
  return countDaysCustomTask;
}

export function getTriadTime(
  lowerDate: string,
  upperDate: string,
  tasks: TaskModel[],
): SelectorTriadTime {
  return {
    important: tasks
      .filter(task => task.label === IMPORTANT)
      .reduce(
        (total, task) =>
          total +
          task.dones.filter(
            i => i.done && isBetweenInteval(i.date, lowerDate, upperDate),
          ).length,
        0,
      ),
    urgent: tasks
      .filter(task => task.label === URGENT)
      .reduce(
        (total, task) =>
          total +
          task.dones.filter(
            i => i.done && isBetweenInteval(i.date, lowerDate, upperDate),
          ).length,
        0,
      ),
    circumstantial: tasks
      .filter(task => task.label === CIRCUMSTANTIAL)
      .reduce(
        (total, task) =>
          total +
          task.dones.filter(
            i => i.done && isBetweenInteval(i.date, lowerDate, upperDate),
          ).length,
        0,
      ),
  };
}

export function isBetweenInteval(
  dateToCompare: string,
  dateLeft: string,
  dateRight: string,
): boolean {
  return (
    parseToNumber(dateLeft) <= parseToNumber(dateToCompare) &&
    parseToNumber(dateToCompare) <= parseToNumber(dateRight)
  );
}


export const getScoreBetweenInteval = (
  lower: string,
  upper: string,
  {
    tasks,
    goals,
    habits,
  }: {tasks: TaskModel[]; goals: Goal[]; habits: HabitModel[]},
): number => {
  const scores = {
    scoreTotalTasksAdded: tasks
      .filter(task => isBetweenInteval(task.createdAt, lower, upper))
      .reduce(
        (total: number, item) => total + (item.period === JUST_TODAY ? 2 : 10),
        0,
      ),
    scoreTotalHabitsAdded:
      habits.filter(habit => isBetweenInteval(habit.createdAt, lower, upper))
        .length * 10,
    scoreTotalGoalAdded: goals.length * 10,
    scoreTotalHabitsDone: habits.reduce(
      (total: number, habit) =>
        total +
        habit.dones
          .filter(i => isBetweenInteval(i.date, lower, upper))
          .filter(i => i.done).length *
          20,
      0,
    ),
    scoreTotalTasksDone: tasks.reduce(
      (total, task) =>
        total +
        task.dones
          .filter(i => isBetweenInteval(i.date, lower, upper))
          .filter(i => i.done).length *
          Gamification.getScoreFromTask(task.label, task.startHour, task.endHour),
      0,
    ),
  };
  return Object.values(scores).reduce((acc, item) => acc + item, 0);
};
