import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/molecules";

import {
  CategoryActions,
  selectorUserId,
  selectorCategories,
  DrawerActions,
} from "@/store/modules";
import { uid } from "@/utils/uid";
import { Input } from "@/components/molecules";
import { isCategoryAdmin } from "@/constants";
import InputPickerColor from "@/components/molecules/InputPickerColor";
import { useTheme } from "styled-components";

const Category = ({ isEditing, category }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const categories = useSelector(selectorCategories);
  const id = useSelector(selectorUserId);
  const [name, setName] = useState(category?.name ? t(category.name) : '',);
  const [color, setColor] = useState(category?.color || "");

  function handleSave() {
    if (category?.uid) {
      if (isCategoryAdmin(category?.uid)) {
        const existingUpdate = categories.find(
          (i) => i.editUid === category.uid
        );
        if (existingUpdate) {
          dispatch(
            CategoryActions.updateCategoriesRequest({
              uid: existingUpdate.uid,
              name,
              color,
            })
          );
        } else {
          dispatch(
            CategoryActions.addCategoriesRequest({
              user_id: id,
              userId: id,
              uid: uid(),
              name,
              color,
              editUid: category.uid,
              createdAt: new Date().toISOString(),
            })
          );
        }
      } else {
        dispatch(
          CategoryActions.updateCategoriesRequest({
            ...category,
            name,
            color,
          })
        );
      }
    } else {
      dispatch(
        CategoryActions.addCategoriesRequest({
          user_id: id,
          userId: id,
          uid: uid(),
          name,
          color,
          createdAt: new Date().toISOString(),
        })
      );
    }
    dispatch(DrawerActions.closeDrawer());
  }

  function handleDelete() {
    if (isCategoryAdmin(category.uid)) {
      const existingUpdate = categories.find((i) => i.editUid === category.uid);
      if (existingUpdate) {
        dispatch(
          CategoryActions.updateCategoriesRequest({
            ...category,
            uid: existingUpdate.uid,
            deletedAt: new Date().getTime(),
          })
        );
      } else {
        dispatch(
          CategoryActions.addCategoriesRequest({
            name: category.name,
            color: category.color,
            userId: id,
            user_id: id,
            uid: uid(),
            editUid: category.uid,
            deletedAt: new Date().getTime(),
            createdAt: new Date().toISOString(),
          })
        );
      }
    } else {
      dispatch(CategoryActions.deleteCategoriesRequest(category));
    }
    dispatch(DrawerActions.closeDrawer());
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Input
        label={t("auth:name")}
        placeholder={t("task:placeholderCategory")}
        value={name}
        onChangeText={setName}
      />

      <InputPickerColor color={color} setColor={setColor} />

      <Button
        title={category ? t("save") : t("add")}
        marginTop={40}
        onPress={handleSave}
      />
      {category && (
        <Button
          backgroundColor={theme.colors.red}
          title={t("delete")}
          marginTop={15}
          onPress={handleDelete}
        />
      )}
    </div>
  );
};

export default Category;
