// import '@/services/ReactotronConfig';
import {Provider} from 'react-redux';
// import {PersistGate} from 'redux-persist/integration/react';
import {store} from './store';
// import Analytics from './services/Analytics';
// import Notification from './services/Notification';
// console.disableYellowBox = true;
import 'react-toastify/dist/ReactToastify.css';
import './locales';
import Config from './Config';

const App = () => {
  return (
    <Provider store={store}>
      <Config />
    </Provider>
  );
};
export default App;
