import {call, put, select} from 'redux-saga/effects';
import {Toast, Analytics, Gamification} from '@/services';
import {getDateToday} from '@/utils/date';
import {uid} from '@/utils/uid';
import {t} from '@/services/Locale';
import {
  StatisticActions,
  HabitActions,
  AddHabit,
  Habit,
  ActivityInDate,
  mapHabit,
} from '@/store/modules';
import {SagaIterator} from '@redux-saga/types';
import * as Api from '@/services/api/habit';
import {Action} from '../utils';
import {RewardActions} from '../reward/duck';
import { HABITO } from '@/constants';

export function* getHabits(): SagaIterator<void> {
  try {
    const data = yield call(Api.listHabits,'2020-01-01', getDateToday());
    yield put(HabitActions.getHabitsSuccess(data,true));
    Analytics.log(Analytics.events.GET_HABITS);
  } catch (err) {
    yield put(HabitActions.setFailure());
  }
}
export function* getHabit(action:any): SagaIterator<void> {
  try {
    const data = yield call(Api.getHabit,action.payload.id);
    yield put(HabitActions.getHabitSuccess(mapHabit(data.habit), data.dates));
  } catch (err) {
    yield put(HabitActions.setFailure());
  }
}

export function* addHabit(
  action: Action<{habit: AddHabit}>,
): SagaIterator<void> {
  Toast.show(t('statistic:addScore', {score: 10}));
  yield put(StatisticActions.addScore(10));
  const firstHabit = yield select(stt => stt.habit.habits.length === 1);
  const {habit} = action.payload;
  Analytics.setUserProperty(Analytics.events.ADD_HABIT, getDateToday());
  if (firstHabit) {
    yield put(
      RewardActions.addRewardRequest({
        uid: uid(),
        user_id: habit.user_id,
        date: getDateToday(),
        key: 'add_first_habit',
        data: {habit},
      }),
    );
  }
  const data = yield call(Api.addHabit,habit);
  yield put(HabitActions.addHabitSuccess({...habit, ...data, dones: []}));
}

export function* updateHabit(
  action: Action<{habit: Habit}>,
): SagaIterator<void> {
  Analytics.setUserProperty(Analytics.events.UPDATE_HABIT);
  const {habit} = action.payload;
  yield call(Api.updateHabit,habit);
  yield put(HabitActions.updateHabitSuccess(habit));
}

export function* checkHabit(
  action: Action<{habit: Habit}>,
): SagaIterator<void> {
  const {habit} = action.payload;
  let score = Gamification.getScoreFromTask(HABITO, habit.startHour, habit.endHour) * (habit.done ? 1 : -1);
  if (score > 0) {
    Toast.show(t('statistic:addScore', {score}));
  }
  yield put(StatisticActions.addScore(score));
  // if (habit.done) yield call(checkRewardActivity, habit);
  Analytics.setUserProperty(Analytics.events.CHECK_HABIT, habit.date);
  yield call(Api.checkHabit,habit);
  yield put(HabitActions.checkHabitSuccess(habit));
}

export function* updateHabitDate(
  action: Action<{habit: Habit; data: ActivityInDate}>,
): SagaIterator<void> {
  const {habit, data} = action.payload;
  if (data.note) Analytics.setUserProperty(Analytics.events.ADD_NOTE);
  if (data.deleted)
    Analytics.setUserProperty(Analytics.events.DELETE_HABIT_DATE);
  yield call(Api.updateHabitDate, habit, data);
  yield put(HabitActions.updateHabitDateSuccess(habit, data));
}

export function* deleteHabit(
  action: Action<{habit: Habit}>,
): SagaIterator<void> {
  Analytics.setUserProperty(Analytics.events.DELETE_HABIT);
  const {habit} = action.payload;
  yield call(Api.deleteHabit,habit);
  yield put(HabitActions.deleteHabitSuccess(habit));
}
