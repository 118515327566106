export const CategoryTypes = {
  GET_CATEGORIES_REQUEST: 'category/GET_CATEGORIES_REQUEST',
  GET_CATEGORIES_SUCCESS: 'category/GET_CATEGORIES_SUCCESS',

  ADD_CATEGORY_REQUEST: 'category/ADD_CATEGORY_REQUEST',
  ADD_CATEGORY_SUCCESS: 'category/ADD_CATEGORY_SUCCESS',

  UPDATE_CATEGORY_REQUEST: 'category/UPDATE_CATEGORY_REQUEST',
  UPDATE_CATEGORY_SUCCESS: 'category/UPDATE_CATEGORY_SUCCESS',

  DELETE_CATEGORY_REQUEST: 'category/DELETE_CATEGORY_REQUEST',
  DELETE_CATEGORY_SUCCESS: 'category/DELETE_CATEGORY_SUCCESS',

  SET_FAILURE: 'category/SET_FAILURE',
  SET_INITIAL_STATE: 'category/SET_INITIAL_STATE',
};

export type Category = {
  user_id?: number;
  userId?: number;
  editUid?: string;
  dbUid?: string;
  name: string;
  uid: string;
  color: string | null;
  deletedAt?: string
  createdAt?: string
};

export interface CategoryState {
  categories: Category[];
  lastSync: null | Date;
}
