import React, {useEffect, useMemo, useState} from 'react';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import Column from './Column';
import {ButtonAddTask, ContainerList} from './styles';
import Header from '@/components/Header';
import {useDispatch, useSelector} from 'react-redux';
import {
  DrawerActions,
  ListActions,
  TaskActions,
  selectorCategories,
  selectorFilterList,
  selectorList,
  selectorTasksWithoutDate,
} from '@/store/modules';
import {getDateToday} from '@/utils/date';
import {useTranslation} from 'react-i18next';
import {Container, Loading, ViewBase} from '@/components/atoms';
import {ListEmpty} from '@/components/molecules';
import {colors} from '@/styles';
import {differenceInMinutes, isSameDay, parseISO} from 'date-fns';
import {handleFilter} from '@/utils/activity';
import Panel from './Panel';

const App = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const list = useSelector(selectorList);
  const tasks = useSelector(selectorTasksWithoutDate);
  const [localTasks, setLocalTasks] = useState(tasks);
  const categories = useSelector(selectorCategories);
  const {filterBy} = useSelector(selectorFilterList);
  const countFilters = useMemo(
    () =>
      filterBy.status.length +
      filterBy.types.length +
      filterBy.categories.length,
    [filterBy],
  );
  const headers = [
    {
      name: t('task:inbox'),
      uid: null,
    },
    ...categories.filter(
      c =>
        (c.createdAt && isSameDay(new Date(), parseISO(c.createdAt))) ||
        localTasks.some(t => t?.categories?.includes(c.uid)),
    ),
  ];

  useEffect(() => {
    setLocalTasks(tasks);
  }, [tasks]);

  function mapTaskToDate(item: any) {
    return {
      ...item,
      done: item.dones.some((i: any) => i.done),
      date: item.dones.find((i: any) => i.date)?.date || getDateToday(),
    };
  }

  function getTasksByCategory(listId: string | null) {
    return localTasks
      .map(mapTaskToDate)
      .filter(t => {
        // if (listId === 'all') return true;
        if (listId === null || listId === 'null') return !t?.categories;
        return t?.categories?.includes(listId);
      })
      .filter(i => handleFilter(i, filterBy));
  }

  function sortByList(prev: string, next: string, list: string[]) {
    if (list?.length) {
      let indexA = list?.indexOf(prev);
      let indexB = list?.indexOf(next);
      indexA = indexA < 0 ? 99 : indexA;
      indexB = indexB < 0 ? 99 : indexB;
      return indexA - indexB;
    }
    return 1;
  }

  function getData() {
    const columnOrder = list.lists.length
      ? list.lists
      : headers
          .map(h => String(h.uid))
          .sort((prev, next) => sortByList(prev, next, list.lists));

    const objectColumns = columnOrder.reduce(
      (acc, item) => ({
        ...acc,
        [item]: {
          ...[...headers, ...categories].find(i => String(i.uid) === item),
          taskIds: getTasksByCategory(item)
            .map(i => String(i.id || i.uid))
            .sort((prev, next) => sortByList(prev, next, list.tasks[item])),
        },
      }),
      {},
    );
    return {
      columns: objectColumns,
      columnOrder: columnOrder,
    };
  }

  const data: any = useMemo(() => {
    return getData();
  }, [localTasks, list, categories, filterBy]);

  useEffect(() => {
    if (
      list.lastSync &&
      differenceInMinutes(new Date(), list.lastSync) < 60 &&
      tasks.length > 0
    )
      return;
    dispatch(ListActions.getListsRequest());
  }, []);

  const onDragEnd = (result: any) => {
    const {destination, source, draggableId, type} = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === 'column') {
      const newColumnOrder: string[] = Array.from(data.columnOrder);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);
      dispatch(ListActions.setListsOrder(newColumnOrder));
      return;
    }
    const [taskId] = draggableId.split('#');

    const start = data.columns[source.droppableId];
    const finish = data.columns[destination.droppableId];

    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, taskId);

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      };

      const newState = {
        ...data,
        columns: {
          ...data.columns,
          [String(newColumn.uid)]: newColumn,
        },
      };
      dispatch(
        ListActions.setTasksOrder(
          Object.keys(newState.columns).reduce(
            (acc, column) => ({
              ...acc,
              [String(column)]: newState.columns[column].taskIds,
            }),
            {},
          ),
        ),
      );
      return;
    }

    const task = localTasks.find(i => String(i.id || i.uid) === taskId);
    const categories = (task?.categories || [])
      .filter(c => c !== start.uid)
      .concat(finish.uid)
      .filter(Boolean);

    const newTask = {
      ...task,
      categories: categories.length ? categories : null,
    };
    if (task) {
      setLocalTasks(stt =>
        stt.map(i =>
          String(i.id || i.uid) === taskId
            ? {...i, categories: categories.length ? categories : null}
            : i,
        ),
      );
      dispatch(TaskActions.updateTaskRequest(newTask as any));
    }

    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    };

    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, taskId);
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    };

    const newState = {
      ...data,
      columns: {
        ...data.columns,
        [String(newStart.uid)]: newStart,
        [String(newFinish.uid)]: newFinish,
      },
    };

    dispatch(
      ListActions.setTasksOrder(
        Object.keys(newState.columns).reduce(
          (acc, column) => ({
            ...acc,
            [String(column)]: newState.columns[column].taskIds,
          }),
          {},
        ),
      ),
    );
  };

  const onAddList = () => {
    dispatch(DrawerActions.setDrawer({action: `ADD_CATEGORY`, payload: {}}));
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Container>
        <Header />
        <Panel initialColumns={headers.map(i => String(i.uid))} />
        {list.loading ? (
          <ViewBase center>
            <Loading size={35} color={colors.primary} />
          </ViewBase>
        ) : (
          localTasks.length === 0 && (
            <ListEmpty
              title={
                countFilters === 0
                  ? `${t('task:noTaskInThisList')}`
                  : t('task:noTaskInThisListFilter')
              }
              text={
                countFilters === 0
                  ? t('listTasksEmpty')
                  : t('task:removeFiltersToSeeList')
              }
              titleButton={t('createTask')}
              onPress={() =>
                dispatch(
                  DrawerActions.setDrawer({
                    action: 'ADD_TASK',
                    payload: {
                      activity: {
                        hasDate: false,
                      },
                    },
                  }),
                )
              }
            />
          )
        )}

        <Droppable
          droppableId="all-columns"
          direction="horizontal"
          type="column">
          {provided => (
            <ContainerList {...provided.droppableProps} ref={provided.innerRef}>
              {data.columnOrder.map((columnId: string, index: number) => {
                const column = data.columns[columnId];
                if (!column.name) return null;
                const tasksColumn = column.taskIds.map((taskId: string) =>
                  mapTaskToDate(
                    localTasks.find(i => String(i.id || i.uid) === taskId),
                  ),
                );
                return (
                  <Column
                    key={String(column.uid)}
                    column={column}
                    tasks={tasksColumn}
                    index={index}
                  />
                );
              })}
              {provided.placeholder}
              <ButtonAddTask
                style={{height: 45, minWidth: 150, width: 150, maxWidth: 150}}
                onClick={onAddList}>
                + {t('newList')}
              </ButtonAddTask>
            </ContainerList>
          )}
        </Droppable>
      </Container>
    </DragDropContext>
  );
};

export default App;
