import { getDifferenceInMinutes } from '@/components/organisms/Agenda/utils';
import {
  CIRCUMSTANTIAL,
  IMPORTANT,
  LEVELS,
  URGENT,
  REWARDS,
  GOAL,
  HABITO,
} from '@/constants/index';
import {t} from '@/services/Locale';

export type Level = {
  text: string;
  textIndex: string;
  scoreDown: number;
  scoreTop: number;
};
export type Reward = {
  key: string;
  image: string;
};

interface IGamification {
  getReward(key: string): Reward;
  getScoreFromTask(label: string, startHour: string|null, endHour: string|null): number;
  getLevelUser(score: number): Level;
  translateLevel(level: Level): Level;
}

class Gamification implements IGamification {
  public readonly rewards: typeof REWARDS;

  public readonly levels: typeof LEVELS;

  constructor() {
    this.rewards = REWARDS;
    this.levels = LEVELS;
  }

  getReward(key: string): Reward {
    return this.rewards.find(i => i.key === key) || this.rewards[0];
  }

  getScoreFromTask(
    label: string,
    startHour: string|null, endHour: string|null,
  ): number {
    const minutes =
      startHour && endHour ? getDifferenceInMinutes(endHour, startHour) : 30;
    const ratioTime = Math.max(5, minutes) / 30;
    const ratioLabel = this.getScoreRatioLabel(label);
    return Math.floor(ratioTime * ratioLabel);
  }
  getScoreRatioLabel(label: string) {
    switch (label) {
      case GOAL:
        return 50;
      case HABITO:
        return 30;
      case IMPORTANT:
        return 20;
      case URGENT:
        return 15;
      case CIRCUMSTANTIAL:
        return 10;
      default:
        return 0;
    }
  }

  getLevelUser(score: number): Level {
    let level = this.levels.find(
      l => score >= l.scoreDown && score < l.scoreTop,
    ) as Level;
    if (score <= 0) {
      level = this.levels[0] as Level;
    }
    if (!level) {
      level = {...this.levels[this.levels.length - 1], scoreTop: score};
    }
    level = this.translateLevel(level);
    return level;
  }

  translateLevel(level: Level): Level {
    return {
      ...level,
      text: t(level.text),
      textIndex: `${level.textIndex.split('-')[0]}-${t(
        `statistic:${level.text}`,
      )}`,
    };
  }
}
// eslint-disable-next-line
export default new Gamification();
