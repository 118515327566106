import {call, put, select} from 'redux-saga/effects';
import {Toast, Gamification, Analytics} from '@/services';
import {INFINITY_DATE, JUST_TODAY} from '@/constants/index';
import {getDateToday} from '@/utils/date';
import {t} from '@/services/Locale';
import {
  TaskActions,
  StatisticActions,
  AddTask,
  Task,
  ActivityInDate,
  mapTask,
  selectorList,
  ListActions,
} from '@/store/modules';
import {SagaIterator} from '@redux-saga/types';
import * as Api from '@/services/api/task';
import {Action} from '../utils';

export function* getTask(action:any): SagaIterator<void> {
  try {
    const data = yield call(Api.getTask,action.payload.id);
    yield put(TaskActions.getTaskSuccess(mapTask(data.task), data.dates));
  } catch (err) {
    yield put(TaskActions.setFailure());
  }
}

export function* addTask(action: Action<{task: AddTask}>): SagaIterator<void> {
  const {task} = action.payload;
  const score = task.period === JUST_TODAY ? 2 : 10;
  Toast.show(t('statistic:addScore', {score}));
  yield put(StatisticActions.addScore(score));
  Analytics.setUserProperty(Analytics.events.ADD_TASK, getDateToday());
  const data = yield call(Api.addTask,task);
  yield put(TaskActions.addTaskSuccess({...task, ...data}));
  const {lists} = yield select(selectorList)
  if(!task.startDate && task.categories && task.categories?.length > 0 && task.categories?.some(c=> !lists.includes(c)) ) {
    const addLists = task.categories?.filter(c=> !lists.includes(c))
    let array = [...lists,...addLists];
    yield put(ListActions.setListsOrder(array));
  }
}

export function* updateTask(action: Action<{task: Task}>): SagaIterator<void> {
  Analytics.setUserProperty(Analytics.events.UPDATE_TASK);
  const {task} = action.payload;
  yield call(Api.updateTask, task);
  yield put(TaskActions.updateTaskSuccess(task));
}

export function* updateTaskDate(
  action: Action<{task: Task; data: ActivityInDate}>,
): SagaIterator<void> {
  const {task, data} = action.payload;
  if (data.note) Analytics.setUserProperty(Analytics.events.ADD_NOTE);
  if (data.deleted)
    Analytics.setUserProperty(Analytics.events.DELETE_TASK_DATE);
  yield call(Api.updateTaskDate,task, data);
  yield put(TaskActions.updateTaskDateSuccess(task, data));
}

export function* deleteTask(action: Action): SagaIterator<void> {
  Analytics.setUserProperty(Analytics.events.DELETE_TASK);
  const {task} = action.payload;
  yield call(Api.deleteTask,task);
  yield put(TaskActions.deleteTaskSuccess(task));
}


export function* checkTask(action: Action<{task: Task}>): SagaIterator<void> {
  const {task} = action.payload;
  let score = Gamification.getScoreFromTask(task.label, task.startHour, task.endHour);
  score = task.done ? score : -score;
  if (score > 0) {
    Toast.show(t('statistic:addScore', {score: String(score)}));
  }
  yield put(StatisticActions.addScore(score));
  Analytics.setUserProperty(Analytics.events.CHECK_TASK, task.date);
  // if (task.done) yield call(checkRewardActivity, task);
  yield call(Api.checkTask,task);
  yield put(TaskActions.checkTaskSuccess(task));
  if (task?.reschedule) {
    yield put(
      TaskActions.updateTaskRequest({
        ...task,
        endDate: task.done ? task.date: INFINITY_DATE,
      } as Task)
    );
  }
}

