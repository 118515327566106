import {call, put, select} from 'redux-saga/effects';
import {Analytics, api} from '@/services';
import {SagaIterator} from '@redux-saga/types';
import {CategoryActions} from './duck';
import {ModalActions} from '../modal/duck';
import {Action} from '../utils';
import { ListActions, selectorList } from '../list';

export const formatViewCategories = (values: any[]) => {
  const editedCategories = [...values].filter((c) => c.editUid);
  const array = editedCategories
    .map(i => ({...i, uid: i.editUid, dbUid: i.uid}))
    .concat(
      [...values].filter(
        i =>
          !i.editUid && !editedCategories.map(j => j.editUid).includes(i.uid),
      ),
    );
  return array.filter(
    (item, index, arr) => arr.findIndex(i => item.uid === i.uid) === index,
  );
};

export function* getCategories(): SagaIterator<void> {
  try {
    const {data} = yield call(api.get, 'categories');
    yield put(CategoryActions.getCategoriesSuccess(formatViewCategories(data)));
    Analytics.log(Analytics.events.GET_CATEGORIES);
  } catch {
    //
  }
}

export function* addCategory(action: Action): SagaIterator<void> {
  try {
    Analytics.setUserProperty(Analytics.events.ADD_CATEGORY);
    const {category} = action.payload;
    const list = yield select(selectorList)
    if(list.lists.length>0){
      let array = [...list.lists,category.uid];
      yield put(ListActions.setListsOrder(array));
    }
    yield call(api.post,'categories', category);
  } catch (err) {
    yield put(ModalActions.setError(err));
  }
}

export function* updateCategory(action: Action): SagaIterator<void> {
  try {
    Analytics.setUserProperty(Analytics.events.UPDATE_CATEGORY);
    const {category} = action.payload;
    yield call(api.put,`categories/${category.uid}`, category)
  } catch (err) {
    yield put(ModalActions.setError(err));
  }
}

export function* deleteCategory(action: Action): SagaIterator<void> {
  try {
    Analytics.setUserProperty(Analytics.events.DELETE_CATEGORY);
    const {category} = action.payload;
    yield call(api.delete,`categories/${category.uid}`, category);
  } catch (err) {
    yield put(ModalActions.setError(err));
  }
}
